import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Grid, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./navbar.css"
import { Image } from '@mui/icons-material';
import primeLogo from "../assets/primeLogo.png"


const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

  
  return (
    <div className='nav-cont'>
      <Grid container className='lg:flex lg:h-[10rem] bg-blue-500 '   >
        <Grid item   className='nav-title' >
          <Grid xs="auto" className='lg:mt-[3rem] lg:ml-[3rem] inline-block float-left' >
            <Typography variant='h5' className=' inline-block prime-title ' sx={{fontWeight: 'bold'}}>Prime Power Services Survey</Typography>
            <Typography className=' lg:py-5 lg:px-[2rem] pl-4'>please take a moment to help us serve you.</Typography>
          </Grid>
        </Grid>
        <Grid item   className=''>
          <img   src={primeLogo} alt="logo" className=' lg:relative top-[2rem] lg:left-[53rem]  nav-logo'/>
        </Grid>
      </Grid>
    </div>
  )
}
