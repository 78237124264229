import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Container, Grid } from '@mui/material';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { useNavigate } from 'react-router-dom';

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,

    },
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: 65 }} />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" sx={{ fontSize: 65 }} />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" sx={{ fontSize: 65 }} />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: 65 }} />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: 65 }} />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};


function CustomerSurvey() {

    const [firstQ, setFirstQ] = React.useState(0);
    const [secondQ, setSecondQ] = React.useState(0);
    const [thirdQ, setThirdQ] = React.useState(0);
    const [fourthQ, setFourthQ] = React.useState(0);
    const [fifthQ, setFifthQ] = React.useState(0);
    const [ratingValue, setRatingValue] = React.useState(0);
    const [isSubmitted, setIsSubmitted] = React.useState(false);


    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitted(true);
        if (fifthQ === 0 ) {
            // Do not submit the form, show error
            alert('Please select the rating');
        } else if (fifthQ >= 1 && fifthQ <=4) {
            // Proceed with form submission
            navigate("/SuccessPage");
        } else if(fifthQ === 5) {
            navigate("/google-review")
        }

    };

    const navigate = useNavigate();
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 1200,
                        height: 200,
                        marginTop:10
                    },
                }}
                className="s-top"
                
            >

            
                <Paper className=' rating-box'>
                    <Box
                        sx={{
                            '& > legend': { mt: 2 },
                        }}
                    >
                        <Typography variant='body1' className='lg:pt-6 lg:pl-5  text-left bold'
                            component="legend"> How would you rate your service experience? </Typography>
                        <Rating
                            className=' float-left lg:ml-[4rem] lg:mt-5  '
                            name="simple-controlled"
                            value={fifthQ}
                            onChange={(event, newValue) => {
                                setFifthQ(newValue);
                            }}
                            sx={{
                                // Change the color of filled stars
                                '& .MuiRating-iconFilled': {
                                    color: '#ff6d75', // Custom color for filled stars
                                },
                                // Change the color of empty stars
                                '& .MuiRating-iconEmpty': {
                                    color: '#ffb3b3', // Custom color for empty stars
                                },
                                // Change the color of hover stars
                                '& .MuiRating-iconHover': {
                                    color: '#ff3d47', // Custom color on hover
                                },
                                fontSize: 50
                            }}
                        />

                        {/* <div>
                            Selected Rating: {fifthQ} 
                        </div> */}

                    </Box>
                    <Grid>
                        <textarea cols="50" rows="4" type='text' placeholder='Please type your feedback here and let us know what to do better...'
                            className='lg:py-4 lg:px-4  lg:w-[40rem] lg:h-[7rem] lg:mt-3 outline-none s-input' />
                    </Grid>
                </Paper>

            </Box>

            <Button 
                    id='sub-btn'
                onClick={handleSubmit} variant="contained">SUBMIT</Button>
        </Container>
    )
}

export default CustomerSurvey

