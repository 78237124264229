import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./navbar.css";
import { Typography } from '@mui/material';

function SuccessPage() {
  return (
    <div className=''>
      <Typography variant='h4' sx={{marginTop: "10rem", fontFamily: 'cursive'}}>Thank you for completing the survey!</Typography>
      <span className='success-icon' >&#128578;</span>
    </div>
  )
}

export default SuccessPage
