
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Routers from './Router/Routers';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    document.title = "Prime Power Survey"
  })


  return (
    <div className="App">
    <Navbar/>
    <Routers/>
      
    </div>
  );
}

export default App;
