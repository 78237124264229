
import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./survey.css"


const GooglePage = () => {
    
    const navigate = useNavigate();
    const googleReview = () => {
        window.location.href = "https://search.google.com/local/writereview?placeid=ChIJxfirzCUf9YgRoMKP01Sp2LM"
    }
   
  return (
    <Box  >
    <Grid container spacing={2}>
        <Paper elevation={3} className='lg:w-[40rem] lg:h-[16rem] lg:ml-[40rem] lg:mt-[10rem] 
            w-[25rem] h-[13rem] ml-[1.5rem] mt-[15rem] ' >
            <Grid container spacing={2}>
                <Grid xs={12} className='lg:mt-2  mt-3'>
                    <Typography sx={{fontWeight: 'bold', fontSize: 20 }} className=''>Post Your Review</Typography>
                </Grid>
                <Grid xs={12} className='lg:mt-[3rem] p-[2rem] lg:p-0'>
                    <Typography>We'd really appreciate if you would take a moment to post your review on Google.</Typography>
                </Grid>
            </Grid>
            <Paper elevation={0} className='lg:mt-[4rem] lg:ml-[10rem]  lg:w-[20rem] lg:h-[4rem]
                ml-[4rem] mt-[0rem] w-[17rem] h-[3rem] post-box'>
                <Grid container spacing={2} className='cursor-pointer' onClick={() => googleReview()}>
                <Grid xs={'auto'} >
                <img src='https://www.pngmart.com/files/16/official-Google-Logo-PNG-Image.png' 
                    alt='google logo' className=' lg:w-[2.5rem] lg:h-[2.5rem] lg:ml-[3rem] lg:mt-7 border rounded-2xl
                        w-[2rem] h-[2rem] ml-[2rem] mt-[1.4rem]'
                        
                    />
                </Grid>
                <Grid xs={'auto'}>
                <Typography variant='body1' className='relative lg:top-9 lg:left-3 bold left-4 top-7  ' sx={{fontWeight: 'bold', }}>
                    Post on Google
                </Typography>
                </Grid>
                <Grid xs={'auto'}>
                    <ArrowForwardIosIcon className=' lg:mt-[2.3rem] lg:ml-[6rem] mt-[1.8rem] opacity-[70%] ml-[4rem]'/>
                </Grid>
     
                </Grid>
            
            </Paper>
        </Paper>
    </Grid>
    </Box>
  )
}

export default GooglePage